$corpadrao: #ff6833;
section#banner {
    width: 100%;
    height: 100%;
    margin-top: 50px;
    h3 {
        font-size: 2.5rem;
        color: $corpadrao;
        text-transform: uppercase;
    }

    p{
        color: #000;
    }

    .carousel-caption {
        right: 15%;
        bottom: -220px;
        left: 55%;
        z-index: 10;
        text-align: left;
        height: 100%;
    }
}

section#primeiro-conteudo {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100%;
    padding: 40px 0;
    width: 100%;
    height: 100%;
    h2{
        span{
            color: $corpadrao;
        }
    }

    p {
        font-size: .9rem;
        span{
        color: $corpadrao;
    }
    }
}

section#segundo-conteudo {
    background-image: url('../../dist/images/home/bg01.png');
    background-size: cover;
    background-position: 100% center;
    background-repeat: no-repeat;
    width: 100%;
    height: 40vh;
}

section#terceiro-conteudo {
    padding: 40px 0;
    background-image: url('../../dist/images/home/bgespec.png');
    background-size: cover;
    background-position: 100% center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    h4{
        font-size: 1.7rem;
        color: $corpadrao;
        text-transform: uppercase;
    }
    p{  
        color: #000;
        span{
            color: $corpadrao;
        }
        
    }
    p.desc {
        font-size: 1.2rem;
    }
    img{
        margin: 5px;
    }
}

section#quarto-conteudo {
    padding: 40px 0;
    background-image: url('../../dist/images/home/bgexame01.png');
    background-size: cover;
    background-position: 100% center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    h5{
        font-size: 1.6rem;
        color: $corpadrao;
        span{
            color:#5e5e62;
        }
    }
    a.button{
        background-color: $corpadrao;
        color: #fff;
        font-weight: 300;
        font-size: 1.4rem;
        padding: 6px 16px;
        border: 2px solid $corpadrao;
        border-radius: 20px;
        text-decoration: none;
        text-transform: uppercase;
        transition: 300ms;
        span{
            font-weight: bold;
        }
        &:hover{
            background-color: #fff;
            color: $corpadrao;
        }
    }
}
// exame 2
section#quinto-conteudo {
    padding: 40px 0;
    background-image: url('../../dist/images/home/bgexame02.png');
    background-size: cover;
    background-position: 100% center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    h5{
        font-size: 1.6rem;
        color: $corpadrao;
        span{
            color:#5e5e62;
            
        }
    }
    a.button{
        background-color: transparent;
        color: #5e5e62;
        font-weight: 300;
        font-size: 1.5rem;
        padding: 6px 20px;
        border: 2px solid $corpadrao;
        border-radius: 20px;
        text-decoration: none;
        text-transform: uppercase;
        transition: 300ms;
        span{
            font-weight: bold;
            color: $corpadrao;
        }
        &:hover{
            background-color: $corpadrao;
            color: #5e5e62;
            span{
                color: #5e5e62;
            }
        }
    }
}

// convenios

section#convenios{
    padding: 40px 0;
    background-image: url('../../dist/images/home/bgconvenios.png');
    background-size: cover;
    background-position: 100% center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    h6{
        text-transform: uppercase;
        font-size: 1.7rem;
        color: #5e5e62;
        span{
            color: $corpadrao;
        }
    }

    div.borda{
        background-color: #fff;
        padding: 20px;
        border-radius: 30px;
        box-shadow: 15px 0 12px -9px #5e5e62;
    }
}

// profissionais

section#profissionais {
    padding: 50px 0;
    background-color: #f1f1f1;
    .imghover {
        .ajuste{
            img{
                -webkit-filter:grayscale(100%);  
                transition: 300ms;
            }
        }
    }
       
    .imghover{
        &:hover{
            img{
                -webkit-filter:grayscale(0%) !important;
                transition: 300ms;
            }
           
        }
    }
    .btns{
        background-color: $corpadrao;
        color: #fff;
        font-weight: 600;
        font-size: .9rem;
        padding: 3px 9px;
        border: 2px solid $corpadrao;
        border-radius: 20px;
        text-decoration: none;
        text-transform: uppercase;
        transition: 300ms;
       
        &:hover,&:active{
            background-color: #fff;
            color: $corpadrao;
        }
    }
    .btn-med {
        background-color: transparent !important;
        border: none !important;
        color: rgb(63, 63, 252);
        padding: 0 ;
        &:active, &:hover{
            border: none;
            box-shadow: none;
        }
    }
    h3{
        font-size: 1.3rem;
        text-transform: uppercase;
        color: $corpadrao;
        margin: 20px 0;
        margin-bottom: 30px;
    }
    h4{
        font-size: 1rem;
        margin-bottom: 0.3rem;
        text-transform: uppercase;
    }

    img{
        width: 90%;
        height: auto;
    }

    .box{
        width: 100%;
        margin-top: 10px;
    }

   p{
    font-size: .85rem;
   }


}

// modal

.modal {
    z-index: 2010;
    .btn{
        background-color: $corpadrao;
        color: #fff;
        font-weight: 300;
        font-size: 1.1rem;
        padding: 4px 10px;
        border: 2px solid $corpadrao;
        border-radius: 20px;
        text-decoration: none;
        text-transform: uppercase;
        transition: 300ms;
        &:hover{
            background-color: #fff;
            color: $corpadrao;
        }
        &:active{
            background-color: #fff;
            color: $corpadrao;
        }
    }
}

// responsivo
@media only screen and (max-width:1025px) {
    section#banner {
        h3{
            font-size: 2rem;
        }
.carousel-caption {
    right: 15%;
    bottom: -160px;
    left: 55%;
    z-index: 10;
    text-align: left;
    height: 100%;
} }
}
@media only screen and (max-width:769px) {
    section#banner {
        margin-top: 225px;
       
       .carousel-caption {
            right: 15%;
            bottom: 0px;
            left: 55%;
            text-align: left;
            height: 100%;
        }
    }
    section#primeiro-conteudo {
        background-position: 40% 100%;
    }

    section#terceiro-conteudo  {
        h4{
            font-size: 1.5rem;
        }
        p.desc{
            font-size: 1rem;
        }
       
    }

    section#quarto-conteudo, section#quinto-conteudo {
        background-position: 55% 100%;
       
    }

}

@media only screen and (max-width:575px) {
   
    section#banner {
        img{
            height: 35vh;
        }
        margin-top:60px;
        h3{
            font-size: 1.2rem;
        }
        p{
            font-size: .9rem;
        }
       .carousel-caption {
            right: 15%;
            bottom: -10px;
            left: 15%;
            text-align: right;
            height: 100%;
        }
    }

    section#segundo-conteudo {
        background-position: 46% 100%;
        width: 100%;
        height: 25vh;
    }

    section#primeiro-conteudo{
        h2{
            font-size: 1.5rem;
        }
    }

    section#terceiro-conteudo  {
        h4{
            font-size: 1.5rem;
        }
        p.desc{
            font-size: 1rem;
        }
       
    }

    section#quarto-conteudo {
        padding: 20px 0;
        background-position: 55% 100%;
        a.button {
            font-size: 1.1rem;
            padding: 3px 10px;
          
        }
        img{
            width: 85%;
            margin: 10px 0;
        }
        .ajuste{
            margin: 0 auto;
        }
    }

    

    section#quinto-conteudo {
        padding: 20px 0;
        background-position: 55% 100%;
        a.button {
            font-size: 1.1rem;
            padding: 3px 10px;
          
        }
        img{
            width: 85%;
            margin: 10px 0;
        }

        .ajuste{
            margin: 0 auto;
        }
    }

    section#convenios{
        h6 {
            font-size: 1.5rem;
        }
        .borda .ajuste {
            margin: 10px 0;
            text-align: center;
        }
    } 

    section#profissionais {
        .ajuste{
            text-align: center !important;
        }
        .box {
            text-align: center;
            width: 100%;
        }
        img {
            margin: 0 auto !important;
            width: 300px;
            height: 100%;
        }
    } 
}

@media only screen and (max-width:321px) {
    section#banner {
        img{
            height: 30vh;
        }
    }
}

